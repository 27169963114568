import { Component, OnInit } from '@angular/core';
import {CallService} from './services/call.service';
import {SpinnerService} from './services/spinner.service';
import { environment } from '../environments/environment';
import {NotificationService} from './services/notification.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {FacPopupComponent} from './fac-popup/fac-popup.component';
import { TermsPopupComponent } from './terms-popup/terms-popup.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'passage';
  form: FormGroup ;
  tdc: any;
  service : any;
  plan : any;
  id_3dshasplan : any;
  show : any = true;
  id_hasplan : any;
  email:any;
  terms : any;

  constructor(private call : CallService,private dialog: MatDialog, private sanitizer: DomSanitizer ,private spinner : SpinnerService, private notify : NotificationService) { 

    this.form = new FormGroup({
      email: new FormControl('', [Validators.email,Validators.required]),
      name : new FormControl('', Validators.required),
      adress: new FormControl('', Validators.required),
      name_tdc : new FormControl('', Validators.required),
      number_tdc: new FormControl('', Validators.required),
      month: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      ccv: new FormControl('', Validators.required)
    });


  }

  ngOnInit(): void {

    if(environment.production){
      console.log("PRODUCCION");
    }else{
      console.log("DESARROLLO");
    }

    this.spinner.open();
    this.call.get(this.call.HOST + "/config/key/" + window.location.host ).subscribe( config =>{
      this.call.get(this.call.HOST + "/ecommerce/plan/" + config.result.value_config).subscribe( plan =>{
          this.plan = plan.result.id;
          this.service = plan.result.service;
          this.call.get(this.call.HOST + "/ecommerce/service/" + plan.result.service).subscribe(service =>{
            this.terms = service.result.terms;
            this.spinner.close();
          });
          
      }, err =>{
        console.log(err);
      })

    }, err =>{
      console.log(err);
      
    })

  }

  checkout(){
    this.spinner.open();
    const data = this.form.value;
    // SUSTITUIMOS VALOR DE TDC
    this.tdc = 'XXXXXXXXXXX' + data.number_tdc.substring(data.number_tdc.length - 4);
    let tdc : any = {};
    let send : any = {};

    tdc.numberCreditCard = data.number_tdc;
      tdc.nameHolderCreditCard = data.name_tdc;
      tdc.expMonthCreditCard = data.month;
      tdc.expYearCreditCard = data.year;
      tdc.ccv = data.ccv;

    send = {creditcard: tdc, name: data.name, email: data.email, address : data.adress, param : 6,plan : this.plan, service : this.service};
    
    this.call.send(send,this.call.HOST + "/suscription").subscribe(response=>{
      // SUSCRIPCION PROCESADA CORRECAMENTE

      this.spinner.close();

     if(response.htmlFormData){ // EN CASO DE QUE SEA 3DS

      this.id_3dshasplan = response.id;
       //this.responseForm = response.htmlFormData; 
       let dialogRef: MatDialogRef<any> = this.dialog.open(FacPopupComponent, {
        width: '50%',
        disableClose: true,
        data: { form: this.sanitizer.bypassSecurityTrustHtml(response.htmlFormData), id: response.id }
      })
      dialogRef.afterClosed()
        .subscribe(res => {
          this.myLoadEvent();
        })
      
     }else{ 
      //window.location.href = "/confirmation?email="+this.form.value.email+"&confirmation=" + response.result;
      
      this.spinner.close();
      if(response.result.status_customerPlan == 1 ){ // ESTO ES QUE SE COBRO Y TODO BIEN CON LA SUSCRIPCION ESTA ACTIVA
        this.show = false;
         this.id_hasplan = response.result.id;
         this.email = response.result.customer.email_customer;
        
         this.spinner.close();
         this.notify.showSuccess("Pago procesado correctamente");
     }else{
      this.spinner.close();
      this.notify.showError("Su pago fue rechazado por el banco");

     }
     
      
     }
    }, (err) =>{
      // OCURRIO ERROR CON LA SUSCRIPCION
      console.log(err);
      this.notify.showError(err);
      this.spinner.close();
      
    }) ; 

  }

  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  myLoadEvent(){

    this.spinner.open();

    this.call.get(this.call.HOST + "/hasplan/" + this.id_3dshasplan).subscribe(chp =>{
      if(chp.result.status_customerPlan  != 4){
         if(chp.result.status_customerPlan == 1 ){ // ESTO ES QUE SE COBRO Y TODO BIEN CON LA SUSCRIPCION ESTA ACTIVA
          this.show = false;
           this.id_hasplan = chp.result.id;
           this.email = chp.result.customer.email_customer;
           // ENVIAMOS EVENTO DE COMPRA A GOOGLE ANALYTICS SOLO SI ESTAMOS EN PRODUCCION
           /*if(environment.production){
             let id_plan : any = localStorage.getItem("plan");
             this
             .googleAnalyticsService
             .eventEmitterCheckout("purchase", "ecommerce", "click", "Suscribirse", id_plan,this.option,chp.customer.id);
           }*/
           this.spinner.close();
           this.notify.showSuccess("Pago procesado correctamente");
       }else{
        this.spinner.close();
        this.notify.showError("Su pago fue rechazado por el banco");
 
       }
       
      }
      
    })
   }


   termsPopUp(data: any = {}) {
    
    let dialogRef: MatDialogRef<any> = this.dialog.open(TermsPopupComponent, {
      width: '100%',
      disableClose: true,
      data: { terms: this.terms }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
      })
  }


}
