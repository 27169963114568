<ngx-spinner type="ball-scale-multiple"> <p style="font-size: 20px; color: white">Espere...</p> </ngx-spinner>
<div class="content">

  <div class="process">
    <div class="bannerone">
      <div ><h1 class="circle">1</h1></div>
      <div><h1>Welcome to Passage!</h1>
      <p>You are steps away from your new apartment at Passage. Enter your card details and you would be charged the amount of <b>USD$ 1000.</b> This will allow us to separate your property while you complete the necessary paperwork and financial arrangement</p>
      </div>
    </div>
    <div class="bannertwo">
      <div class="processtwo"><h1 *ngIf="show" class="circle">2</h1><h1 *ngIf="show">Payment method</h1><hr><h1 class="circle">3</h1><h1>Confirmation</h1>
      </div>
      <div class="form">

        <form *ngIf="show" [formGroup]="form" >
          <div class="rendered-form">
            <div class="two-column-form">
              <div class="form-group field-name">
                  <input  [formControl]="form.controls['name']" type="text" placeholder="Name" class="form-control one-c" name="name" id="name" >
                  <small
                            *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched" 
                            class="form-error-msg"> Name is required </small>
              </div>
              <div class="form-group field-e-mail">
                  <input [formControl]="form.controls['email']"  type="text" placeholder="E-mail" class="form-control one-c" name="e-mail"  id="e-mail" >
                            <small
                            *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                            class="form-error-msg"> Email is required </small>
                
                            <small 
                            *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
                            class="form-error-msg"> Email Invalid. The format must be example@dot.com </small>

              </div>
            </div>
              <div class="form-group-solo field-direccion">
                  <input [formControl]="form.controls['adress']" type="text" placeholder="Address" class="form-control one-c" name="direccion"  id="direccion" >
                  <small
                  *ngIf="form.controls['adress'].hasError('required') && form.controls['adress'].touched" 
                  class="form-error-msg"> Address is required </small>
              </div>
              <div class="card">
                  <h6 access="false" id="control-1373969">Credit card</h6>
          <img src="assets/image/target.jpg">
              </div>
          <div class="two-column-form">
              <div class="form-group field-tc">
                  <input [formControl]="form.controls['name_tdc']" type="text" placeholder="Name Holder" class="form-control one-c" name="tc"  id="tc" >
                  <small
                  *ngIf="form.controls['name_tdc'].hasError('required') && form.controls['name_tdc'].touched" 
                  class="form-error-msg"> Name holder is required </small>
              </div>
              <div class="form-group field-Numero-de-Tarjeta">
                  <input [formControl]="form.controls['number_tdc']" (keypress)="onlyNumberKey($event)"  type="text" placeholder="Card Number" class="form-control one-c" name="Numero-de-Tarjeta"  id="Numero-de-Tarjeta" >
                  <small
                  *ngIf="form.controls['number_tdc'].hasError('required') && form.controls['number_tdc'].touched" 
                  class="form-error-msg"> Card number is required </small>
              </div>
          </div>
          <div class="two-column-form">
              <div class="form-group field-mes-exp">
                  <label for="mes-exp"></label>
                  <select [formControl]="form.controls['month']" class="form-control" name="mes-exp" id="mes-exp">
                      <option  disabled="" selected="" value="" >Expiration Month</option>
                      <option value="01" id="mes-exp-0">January</option>
                      <option value="02" id="mes-exp-1">February</option>
                      <option value="03" id="mes-exp-2">March</option>
                      <option value="04" id="mes-exp-3">April</option>
                      <option value="05" id="mes-exp-4">May</option>
                      <option value="06" id="mes-exp-5">June</option>
                      <option value="07" id="mes-exp-6">July</option>
                      <option value="08" id="mes-exp-7">August</option>
                      <option value="09" id="mes-exp-8">September</option>
                      <option value="10" id="mes-exp-9">October</option>
                      <option value="11" id="mes-exp-10">November</option>
                      <option value="12" id="mes-exp-11">Dicember</option>
                  </select>

                  <small
                  *ngIf="form.controls['month'].hasError('required') && form.controls['month'].touched" 
                  class="form-error-msg"> Expiration Month is required </small>

              </div>
              <div class="form-group field-ano-exp">
                  <label for="ano-exp"></label>
                  <select [formControl]="form.controls['year']"  class="form-control" name="ano-exp" id="ano-exp">
                      <option disabled="" selected="" value="" >Expiration Year</option>
                      <option value="22" id="ano-exp-0">2022</option>
                      <option value="23" id="ano-exp-1">2023</option>
                      <option value="24" id="ano-exp-2">2024</option>
                      <option value="25" id="ano-exp-3">2025</option>
                      <option value="26" id="ano-exp-4">2026</option>
                      <option value="27" id="ano-exp-5">2027</option>
                      <option value="28" id="ano-exp-6">2028</option>
                      <option value="29" id="ano-exp-7">2029</option>
                      <option value="30" id="ano-exp-8">2030</option>
                      <option value="31" id="ano-exp-9">2031</option>
                      <option value="32" id="ano-exp-10">2032</option>
                      <option value="33" id="ano-exp-11">2033</option>
                      <option value="34" id="ano-exp-12">2034</option>
                      <option value="35" id="ano-exp-13">2035</option>
                      <option value="36" id="ano-exp-14">2036</option>
                      <option value="37" id="ano-exp-15">2037</option>
                      <option value="38" id="ano-exp-16">2038</option>
                      <option value="39" id="ano-exp-17">2039</option>
                      <option value="40" id="ano-exp-18">2040</option>
                  </select>

                  <small
                  *ngIf="form.controls['year'].hasError('required') && form.controls['year'].touched" 
                  class="form-error-msg"> Expiration Year is required </small>

              </div>
              <div class="form-group field-CCV">
                  <label for="CCV"></label>
                  <input [formControl]="form.controls['ccv']" maxlength="3"  (keypress)="onlyNumberKey($event)"  type="text" placeholder="CCV" class="form-control" name="CCV"  id="CCV">
              </div>
          </div>
          <div class="bot form-group field-suscribirte">
                  <button  [disabled]="form.invalid"  type="submit" class="btn-default btn" name="suscribirte" style="default" id="suscribirte" (click)="checkout()">Pay now</button>
                  <br>
                  <a class="text-dark" type="button"  style="cursor: pointer;"  (click)="termsPopUp({})" >terms & Conditions</a>
              </div>
          </div>
        </form>

        <div  *ngIf="!show" style="text-align: center">

          <p>Felicidades, tu orden ha sido confirmada con el siguiente código:<b> #{{ id_hasplan }}</b> Recibirás un correo a <b>{{ email }}</b> con tu confirmación y más detalles acerca de tu suscripción.</p>

        </div>

        

    </div></div>

  </div>
  
</div>